export const enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export const CF_ACCESS_AUTHENTICATED_EMAIL_HEADER =
  'cf-access-authenticated-user-email';

export const COACH_SHIFTS_FILTER_KEY = 'coach-shift-filter-key';
export const COACH_FIXED_SHIFTS_FILTER_KEY = 'coach-fixed-shift-filter-key';
