export type Namespace = 'local' | 'develop' | 'production';

type FeatureConfig = {
  [key in Namespace]: {
    dependentDeductionDeclarationForm: boolean;
    coachShift: boolean;
  };
};

const featureConfig: FeatureConfig = {
  production: {
    dependentDeductionDeclarationForm: true,
    coachShift: false,
  },
  develop: {
    dependentDeductionDeclarationForm: true,
    coachShift: false,
  },
  local: {
    dependentDeductionDeclarationForm: true,
    coachShift: true,
  },
};

let currentConfig: FeatureConfig[Namespace] | undefined;

export const featureFlag = {
  init: (namespace: Namespace) => {
    currentConfig = featureConfig[namespace];
  },
  isEnabled: (feature: keyof FeatureConfig[Namespace]) => {
    if (currentConfig === undefined) {
      throw new Error('featureFlag.init has not invoked yet');
    }
    return currentConfig[feature];
  },
};
